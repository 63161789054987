<template lang="pug">
    
</template>

<script lang="ts">
import Vue from 'vue'
import ApplicationService from './../services/ApplicationService'
export default Vue.extend({
    
    async mounted() {
        const oldArticleId = this.$route.params.oldArticleId

        if(oldArticleId) {
            const id = await ApplicationService.getArticleFromOldId(oldArticleId)
            this.$router.push('/article/view/' + id)
        } else {
            this.$router.push('/')
        }
    }
})
</script>

<style lang="scss" scoped>

</style>